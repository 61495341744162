export const CellWrapper = ({ style, children, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minWidth: "90px",
        minHeight: "46px",
        ...style
      }}
      {...props}>
      {children}
    </div>
  )
}

CellWrapper.displayName = "CellWrapper";