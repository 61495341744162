import {useState, useEffect} from 'react';
import { 
  DataGrid, 
} from '@mui/x-data-grid';
import "moment/locale/es";
import { esES } from '@mui/material/locale';
import { createTheme} from '@mui/material/styles';
import './paginationStyles.css';
import '../../translator';
import { useTranslation } from 'react-i18next';

const theme = createTheme(
  esES
);
var _ = require("lodash");


export default function TablaVueltasPortales({data,cols,headCols}) {
  const { t, i18n } = useTranslation();

  const [columnasProcesadas, setColumnasProcesadas] = useState([]);

  useEffect(()=>{
    if(cols.length>0){
      const procCols = cols.map( col => {
        if(col.headerName.split("_")[0] === "turno"){
          return {...col, headerName: t(col.headerName)}
        }
        return col;
      });
      setColumnasProcesadas(procCols)
    }
  }, [cols, i18n.language])
  const defaultCols = [
    {
      field: "id",
      align: "center",
      headerAlign: 'center',
      headerName: t("nombre"),
      width: 100,
      headerClassName:"cell-font",
      hide: true
    },
    {
      field: "nombre",
      align: "center",
      headerAlign: 'center',
      headerName: t("nombre"),
      width: 100,
      headerClassName:"border-right-header cell-font",
    },
    {
      field: "vueltasTotal",
      headerName: t("total"),
      align: "center",
      headerAlign: 'center',
      flex:1,
      valueFormatter: params => params?.value.toFixed(0),       
      cellClassName: "cell-total",
      headerClassName:"border-right-header cell-font",
    },
    {
      field: "tonelajeTotal",
      headerName: t("total"),
      align: "center",
      headerAlign: 'center',
      flex:1,
      cellClassName: "cell-total",
      headerClassName:"border-right-header cell-font",
      valueFormatter: params => params?.value.toFixed(0),       
    },
    {
      field: "tiempoTotal",
      headerName: t("total"),
      align: "center",
      headerAlign: 'center',
      flex:1,
      cellClassName: "cell-total",
      headerClassName: "border-right-header cell-font",
      valueFormatter: params => params?.value.toFixed(2),        
    },
    {
      field: "rendimientoTotal",
      headerName: t("total"),
      align: "center",
      headerAlign: 'center',
      flex:1,
      cellClassName: "cell-total",
      headerClassName:"cell-font",
      valueFormatter: params => params?.value.toFixed(1),         
    }
  ]
  

  

  let columnGroupingModel;
  if(headCols.vueltasPromedio){

    columnGroupingModel = [
      {
        groupId: t("ubicacion"),
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: [
          { field: 'nombre' }, 
        ],
      },
      {
        groupId: `${t("vueltas")} (N°)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.vueltas
      },
      {
        groupId: `${t("tonelaje")} (T)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.tonelaje
      },
      {
        groupId: `${t("tiempo_efectivo")} (H)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.tiempo
      },
      {
        groupId: `${t("rendimiento")} (T/H)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.rendimiento
      },
      {
        groupId: `Promedio Vueltas (V/TE)`,
        align:'center',
        headerAlign:'center',
        headerClassName:'group-header',
        children:headCols.vueltasPromedio
      },
      {
        groupId: `Frentes`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.cargadorDumper,
        width: 100,
      }
    ]

  }
  else {

    columnGroupingModel = [
      {
        groupId: t("portal"),
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: [
          { field: 'nombre' }, 
        ],
      },
      {
        groupId: `${t("vueltas")} (N°)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.vueltas
      },
      {
        groupId: `${t("tonelaje")} (T)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.tonelaje
      },
      {
        groupId: `${t("tiempo_efectivo")} (H)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.tiempo
      },
      {
        groupId: `${t("rendimiento")} (T/H)`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.rendimiento
      },
      {
        groupId: `Frentes`,
        align: "center",
        headerAlign: 'center',
        headerClassName:"group-header",
        children: headCols.cargadorDumper,
      }
    ]

  }

   

  return (
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor:"#465f70",
            color: "#fff",
          },
          '& .MuiDataGrid-columnHeaderTitle':{
            fontWeight:"200"
          },
          '& .MuiDataGrid-iconSeparator':{
            color: "transparent"
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(2n)": { 
              backgroundColor: "rgba(47, 69, 84, .07)"
            },
            "&:hover": {backgroundColor: "rgb(0, 183, 196, 0.15)"}
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders":{
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0"
          }
        }}
        hideFooter
        experimentalFeatures={{ columnGrouping: true }}
        rows={data}
        columns={(columnasProcesadas.length>0)?[{
          field: "id",
          align: "center",
          headerAlign: 'center',
          headerName: t("nombre"),
          width: 100,
          headerClassName:"cell-font",
          hide: true
        },
        {
          field: "nombre",
          align: "center",
          headerAlign: 'center',
          headerName: t("nombre"),
          width: 150,
          headerClassName:"border-right-header cell-font",
        }, ...columnasProcesadas]:defaultCols}
        disableSelectionOnClick
        columnGroupingModel={columnGroupingModel}
        density={"compact"}
        autoHeight
      />
  );
}
