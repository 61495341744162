import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import IconoDumperWhite from "../assets/icons/dumper-white.png";
import { AccountContext } from "../authentication/Account";
import ForgotPassword from "../authentication/ForgotPassword";
import Login from "../authentication/Login";
import Settings from "../authentication/Settings";
import Signup from "../authentication/Signup";
import Status from "../authentication/Status";
import { GeneralMinaContext } from "../context/GeneralMina";
import { PageTimingProvider } from "../context/PageTimingContext";
import { useGetRutaRedireccionEmpresa } from "../hooks/general/useGetRutaRedireccionEmpresa";
import { getRutaAreaOperacion } from "../services/general";
import Administracion from "../views/Administracion";
import { Analytics } from "../views/Analytics";
import ControlDeFrentes from "../views/ControlDeFrentes";
import ControlDiario from "../views/ControlDiario";
import ControlFrentes from "../views/ControlFrentes";
import Dashboard from "../views/Dashboard";
import Equipo from "../views/Equipo";
import FuncionamientoDiario from "../views/FuncionamientoDiario";
import { JustificacionMacro } from "../views/Justificacion/JustificacionMacro";
import NivelCombustible from "../views/NivelCombustible";
import Operaciones from "../views/Operaciones";
import OperacionesMaquina from "../views/OperacionesMaquina";
import { OperacionesMaquinaJumbos } from "../views/OperacionesMaquina/OperacionesMaquinaJumbos";
import PersonasDemo from "../views/PersonasDemo";
import Productividad from "../views/Productividad";
import QuickSight from "../views/Quicksight";
import Registros from "../views/Registros";
import Solicitudes from "../views/Solicitudes";
import SimpleBackdrop from "./molecules/BackDrop";
import { NavDrawerEquipos } from "./organisms/NavDrawerEquipos";
import Tablausuario from "./organisms/TablaUsuario";
import Tablausuarioempresa from "./organisms/TablaUsuarioempresa";
import { InfoMinaProvider } from "../context/InfoMina";

const useStyles = makeStyles({
  botonEquipos: {
    "&:hover": {
      backgroundColor: "rgb(0, 183, 196)"
    },
    textAlign: "center",
    position: "fixed",
    bottom: "5%",
    left: "50%",
    transform: "translateX(780%)",
    zIndex: 999,
    borderRadius: "35px",
    backgroundColor: "#2f4554"
  }
});

const Main = () => {
  const classes = useStyles();

  const location = useLocation();
  const { getSession } = useContext(AccountContext);
  const {
    onHandleSetEndpoint,
    onHandleGetEndpoint,
    NavDrawerEquipo: AliasNavDrawerEquipo,
    setNavDrawerEquipo
  } = useContext(GeneralMinaContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loginOk, setLoginOk] = useState(false);
  const [idEmpresa, setIdEmpresa] = useState(0);
  const [idAreaOperacion, setidAreaOperacion] = useState(0);
  const { RutaRedireccionEmpresa, loadingRutaRedireccionEmpresa } =
    useGetRutaRedireccionEmpresa(idEmpresa);

  useEffect(() => {
    getSession()
      .then((session) => {
        setLoggedIn(true);
        const { idToken } = session;
        const { jwtToken, payload } = idToken;
        const { email, email_verified } = payload;
        try {
          const userType = session["custom:role"];
          const userIdEmpresa = session["custom:id_empresa"];
          const userIdOperacion = session["custom:id_operacion"];
          const userIdAreaOperacion = session["custom:id_area_operacion"];

          setIdEmpresa(userIdEmpresa);
          setidAreaOperacion(userIdAreaOperacion);

          getRutaAreaOperacion(Number.parseInt(userIdEmpresa))
            .then((resp) => onHandleSetEndpoint(resp.data))
            .catch((err) => console.log(err));

          //setRole(userType);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((e) => setLoadingPage(false));
  }, [loginOk]);

  useEffect(() => {
    if (idEmpresa !== 0 && onHandleGetEndpoint().length > 0) {
      setLoadingPage(false);
    }
  }, [idEmpresa, onHandleGetEndpoint]);

  const onHandleStaticRoutes = () => {
    let staticRoutes = [
      <Route key={'static-1'} exact path="/personas-demo" render={() => (<PersonasDemo idEmpresa={4} idMina={4} nombreMina="San Antonio" rutaMina="sanantonio" />)} />,
      <Route key={'static-2'} exact path="/nivelcombustible" render={() => <NivelCombustible />} />,
      <Route key={'static-3'} exact path="/encuentros"><Dashboard /></Route>,
      <Route key={'static-4'} exact path="/maquina" render={(props) => <Equipo {...props} />} />,
      <Route key={'static-5'} exact path="/funcionamientodiario" render={(props) => <FuncionamientoDiario {...props} />} />,
      <Route key={'static-6'} exact path="/register" component={Signup} />,
      <Route key={'static-7'} exact path="/login" component={Main} />,
      <Route key={'static-8'} exact path="/recuperacontrasena" component={ForgotPassword} />,
      <Route key={'static-9'} exact path="/tablausuario" component={Tablausuario} />,
      <Route key={'static-10'} exact path="/tablausuarioempresa" component={Tablausuarioempresa} />,
      <Route key={'static-11'} exact path="/productividad" component={Productividad} />,
      <Route key={'static-12'} exact path="/actualizarusuario" component={Settings} />,
      <Route key={'static-13'} exact path="/recuperarcontrasena" component={ForgotPassword} />,
      <Route key={'static-14'} exact path="/backdrope" component={SimpleBackdrop} />,
      <Route key={'static-16'} exact path="/registros" component={Registros} />,
    ]

    if (idEmpresa) {
      staticRoutes.push(...[<Route key={'static-18'} exact path="/operaciones/maquina"><OperacionesMaquina /></Route>, <Route key={'static-19'} exact path="/operaciones/maquina/jumbo"><OperacionesMaquinaJumbos /></Route>])
    }

    return staticRoutes;
  }

  const onHandleDynamicRoutes = () => {
    return onHandleGetEndpoint()
      .filter((ruta, index, self) => self.findIndex((r) => r.endpoint === ruta.endpoint) === index)
      .map((ruta) => {
        let rutas = [];
        
        let rutasAdminEnComun =  [
          <Route exact path={`/administracion/${ruta.endpoint}`}>
            <Administracion
              idMina={ruta.id_mina}
              nombreMina={ruta.nombre_publico}
              esMinaSubterranea={ruta.es_subterranea}
            />
          </Route>,
          <Route exact path={`/solicitudes/${ruta.endpoint}`}>
            <Solicitudes
              idMina={ruta.id_mina}
              nombreMina={ruta.nombre_publico}
            />
          </Route>,
          <Route
          exact
          path={`/justificacion/macro/${ruta.endpoint}`}>
          <JustificacionMacro
            idMina={ruta.id_mina}
            nombreMina={ruta.nombre_publico}
            rutaMina={ruta.endpoint}
          />
          </Route>
        ];

        let rutasMinaDefault = [
          ...rutasAdminEnComun,
          <Route
          key={`operaciones-${ruta.endpoint}`}
          exact
          path={`/operaciones/${ruta.endpoint}`}>
            <Operaciones
              esMinaSubterrane={ruta.es_subterranea}
              idMina={ruta.id_mina}
              nombreMina={ruta.nombre_publico}
              rutaMina={ruta.endpoint}
            />
          </Route>,
          <Route
          key={`control_de_frentes-${ruta.endpoint}`}
          exact
          path={`/control_de_frentes/${ruta.endpoint}`}>
            <InfoMinaProvider idAreaOperacion={ruta.id_mina}>
              <ControlDeFrentes
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                idEmpresa={ruta.id_empresa}
              />
            </InfoMinaProvider>
          </Route>,
          <Route exact path={`/control_frentes/${ruta.endpoint}`}>
            <ControlFrentes
              idMina={ruta.id_mina}
              nombreMina={ruta.nombre_publico}
              rutaMina={ruta.endpoint}
            />
          </Route>
        ]

        if (ruta.id_mina == 24 || ruta.id_mina == 25 || ruta.id_mina == 26) {
          rutas = [
            <Route
              key={`control-diario-${ruta.endpoint}`}
              exact
              path={`/control_diario/${ruta.endpoint}`}>
              <Operaciones
                esMinaSubterrane={ruta.es_subterranea}
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                rutaMina={ruta.endpoint}
              />
            </Route>,
            <Route
              key={`control_de_frentes-${ruta.endpoint}`}
              exact
              path={`/control_de_frentes/${ruta.endpoint}`}>
              <InfoMinaProvider idAreaOperacion={ruta.id_mina}>
                <ControlDeFrentes
                  idMina={ruta.id_mina}
                  nombreMina={ruta.nombre_publico}
                  idEmpresa={ruta.id_empresa}
                />
              </InfoMinaProvider>
            </Route>,
          ];
          if (idEmpresa == 1) rutas = [...rutas, ...rutasAdminEnComun];
          
        } else if (ruta.id_mina == 4 || ruta.id_mina == 11) {
          rutas = [
            <Route
              key={`control-diario-${ruta.endpoint}`}
              exact
              path={`/control_diario/${ruta.endpoint}`}>
              <Operaciones
                esMinaSubterrane={ruta.es_subterranea}
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                rutaMina={ruta.endpoint}
              />
            </Route>,
            <Route
              exact
              path={`/justificacion/macro/${ruta.endpoint}`}>
              <JustificacionMacro
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                rutaMina={ruta.endpoint}
              />
            </Route>,
            <Route exact path={`/control_frentes/${ruta.endpoint}`}>
              <ControlFrentes
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                rutaMina={ruta.endpoint}
              />
            </Route>
          ];
          if (idEmpresa == 1) rutas = [...rutas, ...rutasAdminEnComun];
        }
        else if (ruta.id_mina == 1 || ruta.id_mina == 27) {
          rutas = [
            ...rutasAdminEnComun,
            <Route exact path={`/control_frentes/${ruta.endpoint}`}>
              <ControlFrentes
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                rutaMina={ruta.endpoint}
              />
            </Route>,
            <Route
              key={`operaciones-${ruta.endpoint}`}
              exact
              path={`/operaciones/${ruta.endpoint}`}>
              <Operaciones
                esMinaSubterrane={ruta.es_subterranea}
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
                rutaMina={ruta.endpoint}
              />
            </Route>,
            <Route exact path={`/control_diario/${ruta.endpoint}`}>
              <ControlDiario
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
              />
            </Route>,

            <Route exact path={`/analytics/${ruta.endpoint}`}>
              <Analytics
                idMina={ruta.id_mina}
                nombreMina={ruta.nombre_publico}
              />
            </Route>
          ]
        }
        else {
          rutas = [
            ...rutasMinaDefault,
          ];
        }

        ruta?.endpoint_externo.length > 0 &&
          ruta?.endpoint_externo?.map((rutaAux) => {
            rutas.push(
              <Route
                exact
                path={`/${rutaAux.nombre}/${ruta.endpoint}`}>
                <QuickSight
                  idMina={ruta.id_mina}
                  nombreMina={ruta.nombre_publico}
                  endpointQuickSight={rutaAux.endpoint}
                  zonaHoraria={ruta.zona_horaria}
                  diff_segundos={ruta.diff_segundos}
                  recarga_automatica={rutaAux?.recarga_automatica}
                  dashboardId={rutaAux?.dashboard_id}
                />
              </Route>
            );
          });

        return rutas;
      })

  }

  const onHandleRedirect = () => {
    if (idEmpresa !== 0) {
      return (
        <Route
          path="/"
          render={() => (
            <Redirect
              to={`/operaciones/${RutaRedireccionEmpresa?.ruta_redireccion}`}
            />
          )}
        />
      )
    }
  }

  const MainUI = () => {
    return (
      <>
        <div
          style={{
            display:
              (location?.pathname?.split("/")[1] == "operaciones" &&
                location?.pathname?.split("/")[2] != "laschispas") ||
                (location?.pathname?.split("/")[1] == "control_diario" &&
                  location?.pathname?.split("/")[2] == "laschispas")
                ? "inline"
                : "none"
          }}
          className={classes.botonEquipos}>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={() => setNavDrawerEquipo(true)}>
            <img
              alt="Icono Dumper"
              src={IconoDumperWhite}
              width={"45px"}
              style={{ color: "white" }}
            />
          </IconButton>
        </div>
        <NavDrawerEquipos
          drawerOpened={AliasNavDrawerEquipo}
          toggleDrawer={setNavDrawerEquipo}
        />
      </>
    )
  }

  return (
    <>
      {!loadingPage ? (
        !loggedIn ? (
          <>
            <Status />
            <Login logOk={setLoginOk} />
            <Settings />
          </>
        ) : (
          <PageTimingProvider idAreaOperacion={idAreaOperacion}>
            <Switch>
              {onHandleStaticRoutes()}
              {onHandleDynamicRoutes()}
              {onHandleRedirect()}
            </Switch>
            <MainUI />
          </PageTimingProvider>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%"
          }}>
          <CircularProgress style={{ color: "#2f4554" }} size={100} />
        </div>
      )}
    </>
  );
};

export default Main;
