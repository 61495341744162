
export const DiferenceInMinutesBetweenTwoDates = (fecha1,fecha2) =>{

    const diferenciaEnMilisegundos = Math.abs(fecha2-fecha1);
    const diferenciaEnMinutos = Math.floor(diferenciaEnMilisegundos/60000);

    return diferenciaEnMinutos;
}

export const DiferenceInDaysBetweenTwoDates = (fecha1, fecha2 = new Date()) =>{

    const diferenciaEnMilisegundos = fecha2 - fecha1;
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
    
    return diferenciaEnDias;
}

export const TodayMinusNdays = (daysToSubtract) => {
    const today = new Date();
    
    const currentTime = today.getTime();
    const dayInMiliseconds = 24 * 60 * 60 * 1000;
    const timeToSubtract = daysToSubtract * dayInMiliseconds;
    const resultDate = new Date(currentTime - timeToSubtract);
    
    resultDate.setHours(0, 0, 0, 0);
    
    return resultDate;
}
