import { useEffect, useState, useCallback } from "react";
import { formatearSoloFechaISO } from "../../commons/FormatearFecha";
import { getDataPlanControlFrentes } from "../../services/controlFrentes";

const activities = [
  "rezagado",
  "amacice",
  "fortificacion",
  "geologia",
  "barrenacion",
  "carga",
  "voladura"
];

export function useGetDataPlanControlFrentes(idSubMina, date, idTurno) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);

  const fetchPlanControlFrentes = useCallback(
    async (idAreaOperacion, fecha, turno) => {
      setLoading(true);

      const parsedFecha = formatearSoloFechaISO(fecha.toISOString());
      const { success, data, error } = await getDataPlanControlFrentes({
        idAreaOperacion,
        fecha: parsedFecha,
        idTurno: turno
      });

      if (success) {
        const newData = [...data].sort((a, b) => a.id_frente - b.id_frente && a.id_grupo_frente - b.id_grupo_frente);

        const uniqueGroups = [
          "Todos",
          ...new Set(newData.map((item) => item.grupo_frente))
        ];
        setData(newData);
        setGroups(uniqueGroups);
      } else {
        setError(error);
      }
      setLoading(false);
    },
    []
  );

  // Ejecutar la función en el primer render o cuando los dependientes cambien
  useEffect(() => {
    fetchPlanControlFrentes(idSubMina, date, idTurno);
  }, [date, idSubMina, idTurno, fetchPlanControlFrentes]);

  return {
    groupsPlanControlFrentes: groups,
    dataPlanControlFrentes: data,
    loadingPlanControlFrentes: loading,
    errorPlanControlFrentes: error,
    refetchData: () => fetchPlanControlFrentes(idSubMina, date, idTurno) // Exponer función refetch
  };
}
