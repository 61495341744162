import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid
} from "@mui/material";
import "moment/locale/es";
import SectionHeader from "../../../components/molecules/SectionHeader";
import { format } from "date-fns";

import IconoEstadoEquipos from '../../../assets/icons/icono-estado-equipos.png';

import '../../../translator';
import { useTranslation } from 'react-i18next';

import { useHistory } from "react-router";
import { TablaEstadosTodosLosEquipos } from "./TablaEstadosTodosLosEquipos";
import { TablaDeCarga } from "../../../components/organisms/TablaDeCarga";
import { obtenerFechaEnFormato } from "../../../commons/utiles";
import { formatDateToUTC, formatearSoloFechaISO } from "../../../commons/FormatearFecha";
import { DiferenceInDaysBetweenTwoDates } from "../../../commons/Formulas";

const AllMaquinasView = ({
    idAreaOp, 
    fecha, 
    tipoTurno, 
    minMax, 
    turnos,
    loadingMaquinas,
    maquinas
}) => {
  const {t} = useTranslation();
  let history = useHistory();

  const onClickMaquinaJumbo = (maq) =>{
    
    if(maq?.ultimo_dato_movimiento){
      let differenceInDaysBetweenLastMovementAndCurrentDay = DiferenceInDaysBetweenTwoDates(formatDateToUTC(maq?.ultimo_dato_movimiento));
      if(differenceInDaysBetweenLastMovementAndCurrentDay > 90){
        localStorage.setItem("fechaObjetivo", formatDateToUTC(new Date()))
      }else{
        localStorage.setItem("fechaObjetivo", formatDateToUTC(maq?.ultimo_dato_movimiento))
      }
    }else{
      localStorage.setItem("fechaObjetivo", formatDateToUTC(new Date()))
    }

    
        localStorage.setItem("dataOpMaq",JSON.stringify({
          ...maq
        }));

        localStorage.setItem("estadosJumbo",JSON.stringify({tipoTurno,minMax}));
        localStorage.setItem("IdMina",idAreaOp);
  
        history.push(`/operaciones/maquina/jumbo`);
     
   } 

   const onClickMaquina = (maq) =>{
     if(maq?.ultimo_dato_movimiento){
      let differenceInDaysBetweenLastMovementAndCurrentDay = DiferenceInDaysBetweenTwoDates(formatDateToUTC(maq?.ultimo_dato_movimiento));
      if(differenceInDaysBetweenLastMovementAndCurrentDay > 90){
        localStorage.setItem("fechaObjetivo", formatDateToUTC(new Date()))
      }else{
        localStorage.setItem("fechaObjetivo", formatDateToUTC(maq?.ultimo_dato_movimiento))
      }
    }else{
      localStorage.setItem("fechaObjetivo", formatDateToUTC(new Date()))
    }

      localStorage.setItem("dataOpMaq",JSON.stringify({
        ...maq
      }));
      history.push(`/operaciones/maquina`);
      
   } 

  return (
    <>

      <br/>
        <Grid>
          <SectionHeader 
            title={t("estado_equipos")}
            subtitle={`${t("ultima_actualizacion")} ${format(fecha, "HH:mm")} hrs`}
            icon={<img src={IconoEstadoEquipos} width="35px" alt=" Icono Estado Equipos"/>}
          />
        </Grid>
      <br/>
      <br/>

      {
        loadingMaquinas || (!maquinas || maquinas.length < 1) ? 
        
        <TablaDeCarga columnas={
            [
                t("maquina"), 
                t("estado"), 
                t("ultima_actualizacion"),
                t("vueltas"),
                t("toneladas"),
                t("horometro"),
                t("horometro_motor_brazos"),
                t("horometro_percutor_brazos")
            ]
        }/> :
        <TablaEstadosTodosLosEquipos
            data={maquinas}
            accionJumbo={onClickMaquinaJumbo}
            accionNoJumbo={onClickMaquina}
            fechaComodin={obtenerFechaEnFormato()+' '+turnos.Dia.hora_inicio}
        />
      }
    </>
  )
}

export default AllMaquinasView;