import React from "react";
import { useInteractividad } from "../../../../context/Interactividad";
import { EditProgressCell } from "./EditProgressCell";
import { NoModeCell } from "./NoModeCell";
import "../TableControlFrentes.css";

export function CellProgresoMetrosTurno({ 
  idFrente,
  metros,
  aditionalData,
  rowIndex, 
  planCompleted 
}) {
  const { mode } = useInteractividad();

  return (
    <section>
      {mode === "progress" && (
        <EditProgressCell
          idFrente={idFrente}
          rowIndex={rowIndex}
          aditionalData={aditionalData}
          metros={metros}
        />
      )}
      {mode !== "progress" && (
        <NoModeCell
          rowIndex={rowIndex}
          metros={metros}
          planCompleted={planCompleted}
        />
      )}
    </section>
  );
}
