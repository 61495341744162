import { useInteractividad } from "../../context/Interactividad";
import { TableControlFrentes } from "../../views/ControlDeFrentes/TableControlFrentes/TableControlFrentes";

export function TableWrapper({ frentes }) {
  const { mode } = useInteractividad();

  return (
    <section>
      <TableControlFrentes key={mode} frentes={frentes} />
    </section>
  )
}