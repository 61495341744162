import React, { useMemo } from "react";
import explosionIcon from "../../../assets/icons/explosion-icon.png";
import circleIcon from "../../../assets/icons/yellow-circle-icon.png";
import { activities, colors, parsedValues } from "../constants-frentes";
import { useInteractividad } from "../../../context/Interactividad";
import { CellWrapper } from "../../../components/molecules/CellWrapper";

const empty = "transparent";
const planColor = colors.at(-1);

const CellPart = ({ 
  children,
  style,
  onClick
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: "100%",
        height: "46px",
        border: "1px solid #efedec",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ...style,
      }}>
      {children}
    </div>
  )
}

export function CellProgresoActividad({
  item, 
  activity, 
  index,
  aditionalData
}) {
  const currentActivity = item[activity];
  const plan = item[`plan_${activity}`];
  
  // 3 = 1, 2 = 0.5, 1.5 = 0.5, 1 = 0, 0 = 0
  const parsedActivity = parsedValues[currentActivity];
  const parsedActivityPlan = parsedValues[plan];
  
  const currentColor = colors[index];
  
  const noModeCellColors = {
    "1-0": [currentColor, currentColor],
    "0-1": [planColor, planColor],
    "0.5-0.5": [currentColor, empty],
    "1-1": [currentColor, currentColor],
    "0.5-1": [currentColor, planColor],
    "0-0": [empty, empty],
    "0-0.5": [planColor, empty],
    "0.5-0": [currentColor, empty]
  };

  const planModeCellColors = {
    "3-3": [planColor, planColor],
    "2-2": [planColor, empty],
    "1.5-1.5": [planColor, empty],
    "1-1": [empty, empty],
  }

  const progressModeCellColors = {
    "3-3": [currentColor, currentColor],
    "2-2": [currentColor, empty],
    "1.5-1.5": [currentColor, empty],
    "1-1": [empty, empty],
  }
  
  const [firstHalf, secondHalf] =
  noModeCellColors[`${parsedActivity}-${parsedActivityPlan}`] || [empty, empty];

  const [firstHalfPlan, secondHalfPlan] = planModeCellColors[`${plan}-${plan}`] || [empty, empty];
  const [firstHalfProgress, secondHalfProgress] = progressModeCellColors[`${currentActivity}-${currentActivity}`] || [empty, empty];
  
  const progressVoladura =
    parsedValues[item["voladura"]] && activity === "voladura";

  const lastAdvancedActivity = activities.reduceRight((acc, activity) => {
    if (parsedValues[item[activity]] > 0 && !acc) {
      acc = activity;
    }
    return acc;
  }, null);
  
  const { 
    mode, 
    cells, 
    saveCells,
  } = useInteractividad();

  const statusA = cells.get(item.id_frente)?.get(index)?.get("a");
  const statusB = cells.get(item.id_frente)?.get(index)?.get("b");

  const bgPlanA = mode === "plan" && statusA === "saved" ? "#e2711d99" : firstHalfPlan;
  const bgPlanB = mode === "plan" && statusB === "saved" ? "#e2711d99" : secondHalfPlan;

  const bgProgressA = mode === "progress" && statusA === "saved" ? `${currentColor}cc` : firstHalfProgress;
  const bgProgressB = mode === "progress" && statusB === "saved" ? `${currentColor}cc` : secondHalfProgress;
  
  return (
    <>
      {mode === "plan" ? (
        <CellWrapper>
          <CellPart
            onClick={() => saveCells(item.id_frente, index, "a", aditionalData)}
            style={{
              borderRight: "none",
              backgroundColor: bgPlanA
            }}
          />
          <CellPart
            onClick={() => saveCells(item.id_frente, index, "b", aditionalData)}
            style={{
              backgroundColor: bgPlanB,
              borderRight: index === 6 ? "1px solid #efedec" : "none",
            }}
          />
        </CellWrapper>
      ) : mode === "progress" ? (
        <CellWrapper>
          <CellPart
            onClick={() => saveCells(item.id_frente, index, "a", aditionalData)}
            style={{
              borderRight: "none",
              backgroundColor: bgProgressA
            }}
          />
          <CellPart
            onClick={() => saveCells(item.id_frente, index, "b", aditionalData)}
            style={{
              backgroundColor: bgProgressB,
              borderRight: index === 6 ? "1px solid #efedec" : "none",
            }}
          />
        </CellWrapper>
      ) : (
        <CellWrapper
          style={{
            justifyContent: parsedActivity <= 0.5 ? "flex-start" : "center",
            background: `linear-gradient(to right, ${firstHalf} 50%, ${secondHalf} 50%)`,
            borderRight: "1px solid #E9EBF8",
          }}>
          <section
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: parsedActivity <= 0.5 ? "50%" : "100%"
            }}>
            {lastAdvancedActivity === activity && !progressVoladura ? (
              <img 
                src={circleIcon}
                alt="circle-icon"
              />
            ) : progressVoladura && firstHalf !== empty && secondHalf !== empty ? (
              <img 
                src={explosionIcon}
                alt="explosion-icon"
              />
            ) : null}
          </section>
        </CellWrapper>
      )}
    </>
  );
}
