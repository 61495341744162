import React, {useState, useEffect} from 'react'

import { TableContainer, TableHead, TableRow, TableCell, Table, TablePagination, TableBody, Button } from "@material-ui/core";
import { Grid } from "@mui/material";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { ComboboxGenerico } from '../../components/atoms/ComboboxGenerico';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { TextfieldGenerico } from '../../components/atoms/TextfieldGenerico';

import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import SectionHeader from '../../components/molecules/SectionHeader';

import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';
import { Switch, FormControlLabel } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { CircularProgress } from '@material-ui/core'

import {utils,writeFile} from "xlsx";
import { milisegundosAHoras } from '../../commons/FormatearTiempo';
import ModalDescargaFrentes from './ModalDescargaFrentes';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });
  
  function agruparPorDescripcionYMaquina(data,fechaInicio,fechaTermino) {
    const groupedData = {};
  
    data.forEach(obj => {
      const key = `${obj.descripcion}_${obj.nombre_maquina}`;      
      const fechaInicio = new Date(obj.fecha_inicio);
      const fechaFinal = new Date(obj.fecha_final);

      if (groupedData[key]) {
        groupedData[key].fecha_inicio = new Date(obj.fecha_inicio) < new Date(groupedData[key].fecha_inicio) ? obj.fecha_inicio : groupedData[key].fecha_inicio;
        groupedData[key].fecha_final = new Date(obj.fecha_final) > new Date(groupedData[key].fecha_final) ? obj.fecha_final : groupedData[key].fecha_final;
        groupedData[key].fecha_final = new Date(obj.fecha_final) > new Date(groupedData[key].fecha_final) ? obj.fecha_final : groupedData[key].fecha_final;
        groupedData[key].toneladas += obj.toneladas;
        groupedData[key].cantidad_registro_tiro += obj.cantidad_registro_tiro;
        groupedData[key].duracion += (fechaFinal - fechaInicio);
      } else {
        groupedData[key] = { ...obj, duracion:(fechaFinal - fechaInicio) };
      }
    });
  
    return Object.values(groupedData);
  }

export const TablaDetalleEquiposFrentes = ({data,loadingEncuentrosFrentes, idMina, nombreMina, ultimoEncuentroFrente, loadingUltimoEncuentroFrente, fechaInicio,fechaTermino}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [filteredData, setFilteredData] = useState([]);
    const [openModalDescarga, setOpenModalDescarga] = useState(false);
    const [estadoFiltro, setEstadoFiltro] = useState({
        'filtrarPor':'',
        'txtBuscar':''
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [showLastEncounters, setShowLastEncounters] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
      };

      const onHandleChangeComboBoxRegsistro = (event) =>{
      
        setEstadoFiltro({...estadoFiltro,filtrarPor:event.target.value})
      }

      const onHandleChangeTextFieldBuscar = (event) =>{
        setEstadoFiltro({...estadoFiltro,txtBuscar:event.target.value})
      }

      function combinarRegistros(array) {
        // Nuevo array donde se almacenarán los registros combinados
        let newArray = [];
        // Objeto para realizar un seguimiento de la información más reciente por nombre de máquina
        let infoPorMaquina = {};
      
        // Iterar sobre el array
        for (let i = 0; i < array.length; i++) {
            let registroActual = array[i];
      
            // Verificar si ya hay información para esta máquina
            if (infoPorMaquina.hasOwnProperty(registroActual.nombre_maquina)) {
                let infoMaquina = infoPorMaquina[registroActual.nombre_maquina];
      
                // Verificar condiciones de combinación
                if (
                    new Date(registroActual.fecha_inicio) - new Date(infoMaquina.fecha_final) <= 10 * 60 * 1000
                ) {
                    // Combinar registros
                    infoMaquina.fecha_final = registroActual.fecha_final;
                    // ... Agregar otras propiedades según sea necesario
                } else {
                    // Si no cumple las condiciones, agregar la información actual y actualizar el seguimiento
                    newArray.push(infoMaquina);
                    infoPorMaquina[registroActual.nombre_maquina] = registroActual;
                }
            } else {
                // Si no hay información para esta máquina, agregar la información actual al seguimiento
                infoPorMaquina[registroActual.nombre_maquina] = registroActual;
            }
        }
      
        // Agregar la información final de cada máquina al nuevo array
        for (const key in infoPorMaquina) {
            newArray.push(infoPorMaquina[key]);
        }
      
        return newArray;
      }
      const filterData = () => {
        if (!estadoFiltro.txtBuscar) {
          setFilteredData(agruparPorDescripcionYMaquina(data,fechaInicio,fechaTermino));
        } else {
          let filtro="";

          if(estadoFiltro.filtrarPor == "equipo"){
            filtro ="nombre_maquina"
          }else if(estadoFiltro.filtrarPor == "lugar"){
            filtro ="descripcion"
          }else{
            filtro =""
          }

          const lowercasedFilter = estadoFiltro.txtBuscar.toLowerCase();
          const filtered = data.filter((item) =>
            estadoFiltro.filtrarPor
              ? item[filtro].toLowerCase().includes(lowercasedFilter)
              : data
          );
          setFilteredData(filtered);
        }
      };

      useEffect(() => {
        filterData();
      }, [estadoFiltro.txtBuscar, estadoFiltro.filtrarPor,data?.encuentrosFrentes]);

    const descargarDataTabla = () => {
      const columnsToInclude = [
        "nombre_maquina",
        "descripcion",
        "fecha_inicio",
        "fecha_final",
        "cantidad_registro_tiro",
        "toneladas",
        "duracion"
      ];
    
      const filteredData = agruparPorDescripcionYMaquina(data,fechaInicio,fechaTermino).map((encuentro) => {
        const filteredEncuentro = {};
        columnsToInclude.forEach((column) => {
          if(column == "duracion"){
            filteredEncuentro[column] = milisegundosAHoras(encuentro[column]);
          }else{
            filteredEncuentro[column] = encuentro[column];
          }
        });
        
        return filteredEncuentro;
      });

      
      const workbook = utils.book_new();
      const worksheetResumen = utils.json_to_sheet(filteredData);
      utils.book_append_sheet(workbook, worksheetResumen, "datos_base");
      
      writeFile(
        workbook,
        `${t("datos")}_Buritica_${fechaInicio}+${fechaTermino}.xlsx`
        );
      };

    function handleChangeSwitch(event) {
      setShowLastEncounters(event.target.checked)
    }

    if(loadingUltimoEncuentroFrente){
      return (
        <Grid container direction='column' justify='center' alignItems='center' fullWidth>
          <Grid item xs={12}>
            <CircularProgress size={100} />
          </Grid>
        </Grid>
      )
    }

  return (
    <Grid>
        <Grid container direction={'row'} justifyContent={'space-between'} wrap='nowrap'>
            <Grid item xs={5}>
                <SectionHeader 
                    title={t("detalle_de_equipos")}
                    // subtitle={'ultima actualizacion 17:59hrs'}
                    icon={<img src={IconoResumenDiario} width="35px" alt=" Icono resumen diario"/>}
                />
            </Grid>
            <Grid item xs={12} container spacing={1} justifyContent={'end'} alignItems={'center'}>
                <Grid item xs={3}>
                  <FormControlLabel 
                    label={`${showLastEncounters ? t('mostrar_ultimo_encuentro') : t('ocultar_ultimo_encuentro')}`}
                    labelPlacement="end"
                    control={
                      <Switch 
                        checked={showLastEncounters}
                        onChange={handleChangeSwitch}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#2F4554',
                            '&:hover': {
                              backgroundColor: alpha('#253642', 0.2)
                            }
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: alpha('#253642', 0.7),
                          }
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                    <ComboboxGenerico 
                        label={t("filtrar_por")}
                        name={'filtro-tabla'}
                        onChange={onHandleChangeComboBoxRegsistro}
                        valueSelected={estadoFiltro.filtrarPor}
                        datos={[
                            {id:1, nombre:"equipo",value:"nombre_maquina"},
                            {id:2, nombre:"lugar", value:"descripcion"},
                            //{id:3, nombre:"actividad",}
                        ]}
                        Icono={<FilterAltOutlinedIcon sx={{mr:1}} style={{color:'rgba(0, 0, 0, 0.54)',marginLeft:'10px'}}/>}
                    />
                </Grid>
                <Grid item xs={3} >
                    <TextfieldGenerico 
                        label={t('buscar')}
                        name={'Buscar'} 
                        onChange={onHandleChangeTextFieldBuscar} 
                        value={estadoFiltro.txtBuscar} 
                        disabled={!estadoFiltro?.filtrarPor}
                        Icon={ <SearchIcon sx={{mr:1}} style={{color:'rgba(0, 0, 0, 0.54)',marginLeft:'10px'}} />}
                    />
                </Grid>
                <Grid item sx={{ maxWidth: 180}} xs={3}>
                    <Button
                        size='medium'
                        type="button"
                        variant="contained"
                        className={classes.root}
                        style={{fontSize:'12px',padding:'11px'}}
                        fullWidth   
                        // onClick={()=> descargarDataTabla()}           
                        onClick={()=>setOpenModalDescarga(true)}        
                        >
                          <span style={{marginRight:'5px'}}>
                            {t("descargar")}
                          </span>
                          <DownloadIcon fontSize='small'/>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <br/>
        <br/>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
             <TableHead>
                <TableRow>
                  {showLastEncounters ? (
                    <>
                      <StyledTableCell align="center">{t("frente")}</StyledTableCell>
                      <StyledTableCell align="center">{t("ultimo_equipo")}</StyledTableCell>
                      <StyledTableCell align="center">{t("fecha")}</StyledTableCell>
                      <StyledTableCell align="center">{t("horas_desde_ultimo_encuentro")}</StyledTableCell>
                    </>
                    ) : (
                    <>
                      <StyledTableCell align="center">{t("equipo")}</StyledTableCell>
                      <StyledTableCell align="center">{t("lugar")}</StyledTableCell>
                      <StyledTableCell align="center">{t("actividad")}</StyledTableCell>
                      <StyledTableCell align="center">{t("ultima_actualizacion")}</StyledTableCell>
                    </>
                    )}
                </TableRow>
              </TableHead>              
              
            <TableBody>
              {showLastEncounters ? (
                loadingUltimoEncuentroFrente ? ( 
                  <StyledTableRow>
                      <StyledTableCell align="center">
                        <CircularProgress size={20}/>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CircularProgress size={20}/>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CircularProgress size={20}/>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CircularProgress size={20}/>
                      </StyledTableCell>
                    </StyledTableRow>
                ) : (
                ultimoEncuentroFrente.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lastEncounter, index) => {
                  const {id, descripcion: frente, nombre: ultimoEquipo, fecha_final: fecha } = lastEncounter

                  const hoursSinceLastEncounter = ((new Date() - new Date(fecha)) / (1000 * 60 * 60)).toFixed(1)

                  return (
                    <StyledTableRow key={`${id}-${index}`}>
                      <StyledTableCell align="center">{frente}</StyledTableCell>
                      <StyledTableCell align="center">{ultimoEquipo}</StyledTableCell>
                      <StyledTableCell align="center">{fecha}</StyledTableCell>
                      <StyledTableCell align="center">{hoursSinceLastEncounter}</StyledTableCell>
                    </StyledTableRow>
                  )
                }))
                ) : (
                loadingEncuentrosFrentes ? (
                  <Grid style={{'backgroundColor':'red'}} container direction='column' justify='center' alignItems='center' fullWidth>
                    <Grid item xs={12}>
                      <CircularProgress size={100} />
                    </Grid>
                  </Grid>
                ) : (
                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((maquina,index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">{maquina.nombre_maquina}</StyledTableCell>
                      <StyledTableCell align="center">{maquina.descripcion}</StyledTableCell>
                      <StyledTableCell align="center">{(maquina.tipo_maquina == 4) ? `${maquina?.cantidad_registro_tiro ? maquina.cantidad_registro_tiro : 0} pernos` : 
                                                     (maquina.tipo_maquina == 3) ? `${maquina?.cantidad_registro_tiro ? maquina?.cantidad_registro_tiro : 0} tiros` : 
                                                     (maquina.tipo_maquina == 5) ? `${milisegundosAHoras(maquina?.duracion)} hr` :
                                                     (maquina.tipo_maquina == 8) ? `${milisegundosAHoras(maquina?.duracion)} hr` : 
                                                     /*(maquina.tipo_maquina == 1) ? `${maquina.toneladas} tons`: ""} */
                                                    (maquina.tipo_maquina == 1) ? `${milisegundosAHoras(maquina?.duracion)} hr`: ""}
                      </StyledTableCell>
                      <StyledTableCell align="center">{format(new Date(maquina.fecha_final),'dd-MM-yy HH:mm:ss')}</StyledTableCell>
                    </StyledTableRow>
                  )
                })) 
              )}
            </TableBody>    
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 30]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("filas_por_pagina")}
          />
        </TableContainer>

        <ModalDescargaFrentes
          idMina={idMina}
          nombreMina={nombreMina}
          onClickClose={setOpenModalDescarga}
          open={openModalDescarga}
        />
    </Grid>
  )
}
