export async function getInfoMina(idAreaOperacion, signal) {
  try {
    const headers = new Headers({
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json"
    });

    const requestParams = {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        id_area_operacion: idAreaOperacion
      }),
      signal
    };

    const response = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/get-subminas",
      requestParams
    );

    if (!response.ok) {
      return { success: false, data: [], error: response.statusText };
    }

    const json = await response.json();

    return { success: true, data: json, error: null };
  } catch (err) {
    return {
      success: false,
      data: [],
      error: err.message
    };
  }
}

export async function actualizarDataFrentes(data) {
  try {
    const headers = new Headers({
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json"
    });

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers
    };

    const response = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/planificacion/frente/actualizar",
      requestOptions
    );

    if (!response.ok) {
      return {
        status: "error",
        message: "Hubo un error al actualizar los datos. Inténtalo de nuevo más tarde."
      }
    }

    const result = await response.json();

    if (result === "OKOK") {
      return {
        status: "success",
        message: "Los datos se han actualizado correctamente."
      }
    } else {
      return {
        status: "error",
        message: "Hubo un error al actualizar los datos. Inténtalo de nuevo más tarde."
      }
    }
  } catch (error) {
    return {
      status: "error",
      message: "Hubo un error al actualizar los datos. Inténtalo de nuevo más tarde."
    }
  }
}