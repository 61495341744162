import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import React from "react";
import { activities, colors } from "../constants-frentes";
import { CellComentario } from "./CellComentario";
import { CellProgresoActividad } from "./CellProgresoActividad";
import { CellProgresoMetrosTurno } from "./ProgresoMetrosTurno/CellProgresoMetrosTurno";

import "./TableControlFrentes.css";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("frente", {
    id: "frente",
    header: () => <div />,
    enableHiding: false,
    cell: (info) => {
      const { frente } = info.row.original;

      return (
        <p
          style={{
            color: "#2C4352",
            fontWeight: "bold",
            fontSize: "12px",
            textAlign: "right",
            width: "100%",
            height: "46px",
            minWidth: "180px",
            marginInline: "auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}>
          {frente.replaceAll("_", " ")}
        </p>
      );
    }
  }),
  columnHelper.accessor("planSemanal", {
    id: "planSemanal",
    header: () => <div />,
    enableHiding: false,
    cell: (info) => {
      const {
        metros_avance_semanal: metrosAvanceSemanal,
        plan_semanal_metros_avance: planSemanalMetrosAvance
      } = info.row.original;

      const { index } = info.row;

      return (
        <div
          style={{
            backgroundColor: "#F89821",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "3px",
            minHeight: "46px",
            paddingInline: "5px",
            marginInline: "6px 6px",
            minWidth: "70px",
            fontWeight: "bold",
            color: "#2C4352",
            boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
            position: "relative"
          }}
          className={`${index === 0 && "leyenda leyenda-plan-semanal"}`}>
          <p style={{ color: "#f3f3f3" }}>{metrosAvanceSemanal}</p>
          <p>/</p>
          <p>{planSemanalMetrosAvance}</p>
        </div>
      );
    }
  }),
  ...activities.map((activity, index) =>
    columnHelper.accessor(activity, {
      id: activity,
      header: () => (
        <div
          style={{
            display: "grid",
            marginBlockEnd: "25px",
            border: "1px solid #E9EBF8",
            borderRight:
              index === activities.length - 1 ? "1px solid #E9EBF8" : "none"
          }}>
          <p
            style={{
              fontWeight: "bold",
              color: "#2C4352",
              paddingBlock: "5px"
            }}>{`${activity[0].toUpperCase()}${activity.slice(1)}`}</p>
          <div
            style={{
              height: "20px",
              width: "100%",
              alignSelf: "end",
              backgroundColor: colors[index]
            }}
          />
        </div>
      ),
      cell: (info) => {
        const item = info.row.original;

        const aditionalData = {
          id_turno: item.id_turno,
          id_area_operacion: item.id_area_operacion,
          fecha: item.fecha
        }

        return (
          <CellProgresoActividad
            item={item}
            activity={activity}
            index={index}
            aditionalData={aditionalData}
          />
        );
      }
    })
  ),
  columnHelper.accessor("metrosAvance", {
    id: "metrosAvance",
    header: () => <div />,
    cell: (info) => {
      const { index } = info.row;
      const item = info.row.original;

      const {
        metros_avance: metros,
        plan_avance: planAvance,
        plan_total: planTotal,
        id_frente,
      } = item;

      const aditionalData = {
        id_turno: item.id_turno,
        id_area_operacion: item.id_area_operacion,
        fecha: item.fecha
      }

      const planCompleted = planTotal > 0 && planAvance <= 0;

      return (
        <CellProgresoMetrosTurno
          idFrente={id_frente}
          metros={metros}
          aditionalData={aditionalData}
          rowIndex={index}
          planCompleted={planCompleted}
        />
      );
    }
  }),
  columnHelper.accessor("comentario", {
    id: "comentario",
    header: () => <div />,
    cell: (info) => {
      const { 
        comentario, 
        id_frente, 
        id_turno, 
        id_area_operacion, 
        fecha 
      } = info.row.original;

      const aditionalData = {
        id_turno: id_turno,
        id_area_operacion: id_area_operacion,
        fecha: fecha
      }

      return (
        <CellComentario 
          idFrente={id_frente}
          comentario={comentario}
          aditionalData={aditionalData}
        />
      );
    }
  })
];

export function TableControlFrentes({ frentes }) {
  const table = useReactTable({
    data: frentes || [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <table
      style={{
        marginRight: "auto",
        borderSpacing: "0 5px"
      }}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{ height: "40px" }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <span>No se encontraron resultados.</span>
              </td>
            </tr>
          )}
        </tbody>
      }
    </table>
  );
}
