import ErrorIcon from "@mui/icons-material/Error";
import React, { useEffect, useRef, useState } from "react";
import { useInteractividad } from "../../../context/Interactividad";
import { upperCaseFirstLetter } from "../../../commons/Formatos";

function formatComentario(comentario) {
  return comentario
    .split(" ")
    .map((el, index) => {
      const draft = el.toLowerCase();
      return index === 0 ? upperCaseFirstLetter(draft) : draft;
    })
    .join(" ");
}

export function CellComentario({ 
  idFrente,
  comentario,
  aditionalData
}) {
  const commentRef = useRef(null);
  const originalCommentReft = useRef(comentario)
  const [currentLength, setCurrentLength] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const { mode, saveComment } = useInteractividad();
  const isProgress = mode === "progress";
  const MAX_LENGTH = 50;

  useEffect(() => {
    if (commentRef.current) {
      const formattedComentario = comentario ? formatComentario(comentario) : "";
      commentRef.current.innerText = formattedComentario;
      setCurrentLength(formattedComentario.length);
    }
  }, [comentario]);

  const handleInput = () => {
    if (commentRef.current) {
      let newText = commentRef.current.innerText;

      // Evitar que queden espacios en blanco o saltos de línea residuales
      if (newText.trim() === "") {
        newText = "";
        commentRef.current.innerText = newText;
      }

      // Restringir a MAX_LENGTH caracteres
      if (newText.length > MAX_LENGTH) {
        newText = newText.slice(0, MAX_LENGTH);
        commentRef.current.innerText = newText;
        // Mover el cursor al final del texto
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(commentRef.current);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      setCurrentLength(newText.length);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      commentRef.current.blur(); 
      handleSaveComment();
    }
  };

  const handleSaveComment = () => {
    if (!commentRef.current) return;

    const newComment = commentRef.current.innerText.trim();

    if ((newComment && newComment !== originalCommentReft.current)
       || originalCommentReft.current
    ) {
      saveComment(idFrente, newComment, aditionalData);
      originalCommentReft.current = newComment;
    }
  };

  if (mode === "plan") return null;

  const overLimit = currentLength >= MAX_LENGTH;

  return (
    <section
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "5px",
        
        marginInline: "5px",
      }}
    >
      {comentario ? (
        <ErrorIcon sx={{ margin: "0" }} fontSize="small" color="error" />
      ) : isProgress ? (
        <ErrorIcon sx={{ margin: "0" }} fontSize="small" color="disabled" />
      ) : null}
      <div
        style={{
          filter: isProgress && isEditing && !overLimit ? "drop-shadow(0px 0px 0.85px rgb(0, 81, 255))" : overLimit && isEditing ? "drop-shadow(0px 0px 0.85px rgb(224, 37, 37))" : "drop-shadow(.5px .5px .75px rgba(0,0,0,0.3))",
        }}
      >
      <div
        style={{
          display: !comentario && !isProgress ? "none" : "block",
          minWidth: "165px",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          height: "46px",
          backgroundColor: "#f3f3f3",
          borderRadius: "5px",
          clipPath:
          "polygon(100% 0%, 100% 50%, 100% 100%, 13.5% 100%, 0% 50%, 13.5% 0)",
          marginInlineEnd: "3px",
          position: "relative",
        }}
        >
        <p
          onFocus={() => setIsEditing(true)}
          onBlur={() => {
            setIsEditing(false)
            handleSaveComment()
          }}
          ref={commentRef}
          contentEditable={isProgress}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          suppressContentEditableWarning={true}
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            lineHeight: "1.2",
            paddingBlock: "5px", 
            paddingInline: "20px 5px",
            maxWidth: "165px",
            fontSize: "12px",
            fontWeight: "normal",
            color: "#2C4352",
            height: "46px",
            overflow: "hidden", // Evita el desbordamiento
            outline: "none"
          }}
          />
        {isProgress && (
          <small
            style={{
              position: "absolute",
              bottom: "0.25px",
              right: "2.5px",
              fontSize: "10.5px",
              color: overLimit && isEditing ? "#f44336" : "#2C4352",
            }}>
            {currentLength}/{MAX_LENGTH}
          </small>
        )}
      </div>
      </div>
    </section>
  );
}
