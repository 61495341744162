export const Divider = ({ style, ...props}) => {
  return (
    <div 
      style={{
        width: "100%",
        height: "2px",
        backgroundColor: "#f0f0f0",
        borderRadius: "100vw",
        ...style
      }}
      {...props}
    />
  )
}