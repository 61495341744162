import React, { useEffect } from "react";
import "./marker.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useGetGeoCercas } from "../../hooks/useGetGeoCercas";
import BarMapVacio from "../Produccion/BarMapVacio";

export const MapaGPS = ({id_area_operacion,id_maquina, fecha, loadingMovimientosGPS, movimientosGPS}) => {
  const { loadingGeoCercas, GeoCercas } = useGetGeoCercas(id_area_operacion, id_maquina,false);
  const { loadingGeoCercas:loadingGeoCercasVelocidad, GeoCercas:GeoCercasVelocidad } = useGetGeoCercas(id_area_operacion,id_maquina,true);

  useEffect(() => {
    if(!loadingMovimientosGPS && movimientosGPS?.movimientos?.Todos.length > 0){
      mapboxgl.accessToken =process.env.REACT_APP_MAP_TOKEN;
      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/satellite-v9",
        projection:"globe",
        center: movimientosGPS?.movimientos?.Todos[0],
        zoom: 15.5,
        attributionControl: false,
      });
      
  
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              speeds: movimientosGPS.velocidades,
            },
            geometry: {
              coordinates: movimientosGPS?.movimientos?.Todos
                ? movimientosGPS.movimientos.Todos
                : [],
              type: "LineString",
            },
          },
        ],
      };
  
      const divMarker = document.createElement("div");
      divMarker.className = "marker";
  
      const speeds = movimientosGPS.velocidades;
      if (speeds) {


        
        map.on("load", () => {
          if (movimientosGPS?.movimientos && movimientosGPS?.movimientos?.Todos.length > 0) {
            const features = [];

            for (let i = 0; i < movimientosGPS?.movimientos?.Todos.length - 1; i++) {
              const velocidad = speeds[i]; 
              const segment = {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [movimientosGPS?.movimientos?.Todos[i], movimientosGPS?.movimientos?.Todos[i + 1]], 
                },
                properties: {
                  velocidad: velocidad, 
                },
              };
              features.push(segment);
            }

            const geojson = {
              type: 'FeatureCollection',
              features: features,
            };

            map.addSource('segmentosGps', {
              type: 'geojson',
              data: geojson,
            });

            map.addSource('movimientoGps', {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: movimientosGPS?.movimientos?.Todos,
                },
              },
            });

            let ultimaCoordenada = movimientosGPS.movimientos.Todos[movimientosGPS.movimientos.Todos.length-1]
            new mapboxgl.Marker(divMarker)
              .setLngLat([ultimaCoordenada[0],ultimaCoordenada[1]])
              .addTo(map);
          }

          map.addLayer({
            id: 'segmentosGps',
            type: 'line',
            source: 'segmentosGps',
            paint: {
              'line-width': 6,
              'line-color': '#FFC300',
            },
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
          });
        
          map.addLayer({
            id: 'speedLabels',
            type: 'symbol',
            source: 'segmentosGps',
            layout: {
              'text-field': ['concat', ['to-string', ['get', 'velocidad']], ' km/h'], 
              'text-size': 16,
              "text-anchor": "top",
            },
            paint: {
              'text-color': 'white', 
            },
          });   
  
          if (!loadingGeoCercas) {
            GeoCercas.coordsGeoCerca.forEach((geocerca) => {
              const nombre = geocerca.nombre;
              const coordinates = geocerca.coords;
              const id = geocerca.id;
  
              const geojsonFeature = {
                type: "Feature",
                properties: {
                  id,
                  nombre,
                },
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates],
                },
              };
  
              map.addSource(nombre, {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: [geojsonFeature],
                },
              });
  
              map.addLayer({
                id: nombre,
                type: "fill",
                source: nombre,
                layout: {},
                paint: {
                  "fill-color": "rgb(0, 183, 196)",
                  "fill-opacity": 0.5,
                },
              });
  
              map.addLayer({
                id: `${id.toString()}-label`,
                type: "symbol",
                source: nombre,
                layout: {
                  "text-field": nombre,
                  "text-font": ["Open Sans Regular"],
                  "text-size": 13,
                },
                paint: {
                  "text-color": "white",
                  "text-halo-color": "black", 
                  "text-halo-width": 1, 
                },
              });
            });
          }

          if (!loadingGeoCercasVelocidad) {
            GeoCercasVelocidad.coordsGeoCerca.forEach((geocerca) => {
              const nombre = geocerca.nombre;
              const coordinates = geocerca.coords;
              const id = geocerca.id;
  
              const geojsonFeature = {
                type: "Feature",
                properties: {
                  id,
                  nombre,
                },
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates],
                },
              };
  
              map.addSource(nombre, {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: [geojsonFeature],
                },
              });
  
              map.addLayer({
                id: nombre,
                type: "fill",
                source: nombre,
                layout: {},
                paint: {
                  "fill-color": "rgb(255, 165, 0)",
                  "fill-opacity": 0.5,
                },
              });

              map.addLayer({
                id: `${id.toString()}-label`,
                type: "symbol",
                source: nombre,
                layout: {
                  "text-field": nombre,
                  "text-font": ["Open Sans Regular"],
                  "text-size": 13,
                },
                paint: {
                  "text-color": "white", // Color del texto (blanco)
                  "text-halo-color": "black", // Color del fondo del texto (oscuro)
                  "text-halo-width": 1, // Grosor del fondo del texto
                },
              });

            });
          }

            // map.addLayer({
            //   id: 'movimientoGps',
            //   type: 'line',
            //   source: 'movimientoGps',
            //   paint: {
            //     'line-width': 6,
            //     'line-color': '#FFC300',
            //   },
            //   layout: {
            //     'line-cap': 'round',
            //     'line-join': 'round',
            //   },
            // });

    
        });
      }

    }
  }, [movimientosGPS, fecha, loadingMovimientosGPS]);

  if(loadingMovimientosGPS || !movimientosGPS || !movimientosGPS?.movimientos?.Todos || movimientosGPS?.movimientos?.Todos.length < 1){
    return (
      <BarMapVacio
      labels={['1', '2']}
      height="300"
      loading={false}
    />
    )
  }
  return (
    <div>
      <div id="map" style={{ width: "100%", height: "400px" }} />
    </div>
  );
};
