import { createContext, useContext, useState } from "react";
import { activities } from "../views/ControlDeFrentes/constants-frentes";
import { actualizarDataFrentes } from "../services/controlDeFrentes";

const InteractividadContext = createContext(null);

export const InteractividadProvider = ({ children }) => {
  const [mode, setMode] = useState("none");
  const [cells, setCells] = useState(new Map());
  const [comments, setComments] = useState(new Map());
  const [meters, setMeters] = useState(new Map());
  const [aditionalData, setAditionalData] = useState({});

  function handleMode(newMode) {
    setMode(newMode);

    if (newMode === "none") {
      setCells(new Map());
      setComments(new Map());
      setMeters(new Map());
      setAditionalData({});
    }
  }

  function saveCells(idFrente, cellIndex, part, aditional) {
    setAditionalData(aditional);

    setCells((prevCells) => {
      const draft = new Map(prevCells);
  
      if (!draft.has(idFrente)) {
        draft.set(idFrente, new Map());
      }
  
      const progress = draft.get(idFrente);

      progress.clear();
  
      // Guardar el estado desde cellIndex hacia atrás
      for (let i = cellIndex; i >= 0; i--) {
        if (!progress.has(i)) {
          progress.set(i, new Map());
        }
  
        const cells = progress.get(i);
  
        if (part === "a") {
          cells.set("a", "saved");
          if (i !== cellIndex) cells.set("b", "saved");
          else {
            cells.delete("b");
          }
        } else if (part === "b") {
          cells.set("b", "saved");
          if (i !== cellIndex) {
            cells.set("a", "saved");
          } else {
            cells.delete("a");
          }
          cells.set("a", "saved");
        }
      }
      
      progress.forEach((_, key) => {
        if (key > cellIndex) {
          progress.delete(key);
        }
      });
  
      return draft;
    });
  }

  function saveComment(idFrente, comment, additionalData) {
    setAditionalData(additionalData);
    setComments((prevComment) => {
      const draft = new Map(prevComment);

      if (!draft.has(idFrente)) {
        draft.set(idFrente, "");
      }

      draft.set(idFrente, comment);

      return draft;
    })
  }

  function saveMeters(idFrente, metros, additionalData) {
    setAditionalData(additionalData)

    setMeters((prevMeters) => {
      const draft = new Map(prevMeters);
      
      if (!draft.has(idFrente)) {
        draft.set(idFrente, "");
      }

      draft.set(idFrente, metros);

      return draft;
    })
  }

  async function sendEditedPlan() {
    if (mode === "plan" && cells.size < 1) {
      return {
        status: "warning",
        message: "No se han realizado cambios en la planificación."
      }
    }

    if (mode === "progress" && cells.size < 1 && comments.size < 1 && meters.size < 1) {
      return {
        status: "warning",
        message: "No se han realizado cambios en el progreso."
      } 
    }

    let dataToSend = {
      ...aditionalData,
      "action": mode === "plan" ? "editar-plan" : "editar-progreso",
      "data": []
    }

    const processedIds = new Set();

    if (cells.size >= 1) {
      for (const [idFrente, cell] of cells) {
        const [latestValue] = cell.entries();
        const [index, latestActivity] = latestValue;

        const planData = {
          id_frente: idFrente,
          ultima_actividad: activities[index],
          estado: latestActivity.has("b") ? "completo" : "incompleto"
        }

        if (mode === "progress" && comments.get(idFrente)) {
          planData.comentario_actualizado = comments.get(idFrente);
        }

        if (mode === "progress" && meters.get(idFrente)) {
          planData.metros_actualizado = meters.get(idFrente);
        }

        dataToSend.data.push(planData);
        processedIds.add(idFrente);
      }
    }

    // Caso en el que solo se editan comentarios y/o metros
    if (mode === "progress") {
      // Usar un mapa para acceso rápido a los elementos existentes en dataToSend.data
      const dataMap = new Map(dataToSend.data.map((item) => [item.id_frente, item]));
    
      if (comments.size > 0) {
        for (const [idFrente, comment] of comments) {
          if (!processedIds.has(idFrente) && comment) {
            if (dataMap.has(idFrente)) {
              // Actualizar el comentario si el id_frente ya existe
              dataMap.get(idFrente).comentario_actualizado = comment;
            } else {
              // Agregar un nuevo objeto si el id_frente no existe
              const newData = { id_frente: idFrente, comentario_actualizado: comment };
              dataToSend.data.push(newData);
              dataMap.set(idFrente, newData);
            }
          }
        }
      }
      
      if (meters.size > 0) {
        for (const [idFrente, metros] of meters) {
          if (!processedIds.has(idFrente) && metros) {
            if (dataMap.has(idFrente)) {
              // Actualizar los metros si el id_frente ya existe
              dataMap.get(idFrente).metros_actualizado = metros;
            } else {
              // Agregar un nuevo objeto si el id_frente no existe
              const newData = { id_frente: idFrente, metros_actualizado: metros };
              dataToSend.data.push(newData);
              dataMap.set(idFrente, newData);
            }
          }
        }
      }
    }

    const result = await actualizarDataFrentes(dataToSend);
  
    handleMode("none");
    return result;
  }

  return (
    <InteractividadContext.Provider value={{ 
      mode, 
      handleMode,
      cells,
      saveCells,
      saveComment,
      saveMeters,
      sendEditedPlan
    }}>
      {children}
    </InteractividadContext.Provider>
  );
}

export const useInteractividad = () => {
  const context = useContext(InteractividadContext);
  
  if (!context) {
    throw new Error("useInteractividad must be used within an InteractividadProvider");
  }

  const { 
    mode, 
    handleMode,
    cells,
    saveCells,
    saveComment,
    saveMeters,
    sendEditedPlan,
  } = context;

  return { 
    mode, 
    handleMode,
    cells,
    saveCells,
    saveComment,
    saveMeters,
    sendEditedPlan,
  };
}