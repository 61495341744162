import { Alert, Snackbar } from "@mui/material";
import React from "react";

export const SnackbarWithAlert = ({
  open,
  severity,
  message,
  onClose
}) => {

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

SnackbarWithAlert.displayName = "SnackbarWithAlert";