import React, { useEffect, useState } from "react";
import { WrapperCellProgreso } from "./WrapperCellProgreso";
import { useInteractividad } from "../../../../context/Interactividad";

const noProgressColor = "#E9EBF8";
const progressColor = "#01B7C4";

export function EditProgressCell({
  idFrente,
  rowIndex,
  aditionalData,
  metros
}) {
  const { cells, saveMeters } = useInteractividad();
  const [inputValue, setInputValue] = useState("");
  const [autoSetFour, setAutoSetFour] = useState(false);
  
  const status = cells.get(idFrente);
  const arrivedToVoladura = status?.size === 7 && status.get(6).size === 2;
  
  useEffect(() => {
    if (arrivedToVoladura && !autoSetFour && (inputValue === "" || inputValue === "0")) {
      // Asignar automáticamente "4" solo si llegó a voladura y no está ya en "4"
      setInputValue("4");
      saveMeters(idFrente, "4", aditionalData);
      setAutoSetFour(true);
    } else if (!arrivedToVoladura && autoSetFour) {
      // Limpiar el valor solo si retrocede de voladura y el valor actual es "4"
      setInputValue("");
      saveMeters(idFrente, "", aditionalData);
      setAutoSetFour(false);
    }
  }, [arrivedToVoladura, inputValue, autoSetFour]);

  function handleChangeValue(event) {
    const { value } = event.target;

    // permitir una cadena vacía para que el usuario pueda borrar
    if (value === "") {
      setInputValue("");
      saveMeters(idFrente, "", aditionalData);
      setAutoSetFour(false);
      return;
    }

    // validar que el input sea un número o tenga decimales
    // pero no más de tres dígitos si es decimal
    if (!/^(?:\d{1,2}|\d\.\d?)$/.test(value)) return;
    
    // validar que el valor sea mayor o igual a 0
    if (Number(value) < 0) return;

    // validar que el número sea nuevo o diferente del actual (metros)
    if (Number(value) === metros) return;
    
    setInputValue(value);
    saveMeters(idFrente, value, aditionalData);
    setAutoSetFour(false);
  }

  return (
    <WrapperCellProgreso
      className={`${rowIndex === 0 && "leyenda leyenda-progreso-metros"}`}
      style={{
        backgroundColor: arrivedToVoladura || metros  > 0 || inputValue !== "" || inputValue === "0" ? progressColor : noProgressColor,
        transition: "background-color 100ms ease"
      }}>
      <input
        style={{
          color: arrivedToVoladura || metros  > 0 || inputValue !== "" || inputValue === "0" ? "#fff" : "#2C4352",
        }}
        value={inputValue}
        onChange={handleChangeValue}
        className="input-metros-avance-edit" 
        type="text" 
        placeholder={metros}
      />
    </WrapperCellProgreso>
  )
}