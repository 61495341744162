import { useEffect, useState } from 'react'
import { getGruposMotivosJustificaciones } from '../services/justificacion';

export const useGetGruposMotivosJustificaciones = (id_area_operacion) => {
 const [loadingGrupoJustificaciones, setLoadingGrupoJustificaciones] = useState(false);
 const [gruposJustificaciones, setGruposJustificaciones] = useState([]);

 let idMina;

 if(id_area_operacion == 16 || id_area_operacion == 19){
    idMina = id_area_operacion
 }else{
    idMina = 20
 }

 useEffect(() => {
   setLoadingGrupoJustificaciones(true);
   getGruposMotivosJustificaciones(idMina)
    .then(res => {
            setGruposJustificaciones(res.data)
            setLoadingGrupoJustificaciones(false);
        })
    .catch(err => {
            console.log(err)
            setLoadingGrupoJustificaciones(false);
        })

 }, [id_area_operacion])
 
 return {loadingGrupoJustificaciones, gruposJustificaciones}
}
