import React from "react";
import { WrapperCellProgreso } from "./WrapperCellProgreso";

const noProgressColor = "#E9EBF8";
const progressColor = "#01B7C4";
const startColor = "#097B83";
const completedStartColor = "#FFCE00";
const startShape =
  "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)";

export function NoModeCell({
  rowIndex,
  metros,
  planCompleted
}) {
  return (
    <WrapperCellProgreso
      className={`${rowIndex === 0 && "leyenda leyenda-progreso-metros"}`}
      style={{
        backgroundColor: metros > 0 ? progressColor : noProgressColor,
      }}>
      {metros > 0 ? (
        <section
          style={{
            display: "flex",
            minWidth: "30px",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "3px",
            fontWeight: "bold",
            color: "#fff"
          }}>
          <p>+</p>
          <p>{metros}</p>
          <div
            style={{
              width: "20px",
              height: "20px",
              clipPath: startShape,
              backgroundColor: planCompleted ? completedStartColor : startColor
            }}
          />
        </section>
      ) : (
        <>
          <p
            style={{
              color: "#2C4352",
              fontWeight: "bolder",
              marginBlockStart: "2px",
              marginInline: "4px 2px"
            }}>
            {metros}
          </p>
          {planCompleted && (
            <div
              style={{
                width: "20px",
                height: "20px",
                clipPath: startShape,
                backgroundColor: completedStartColor
              }}
            />
          )}
        </>
      )}
    </WrapperCellProgreso>
  )
}