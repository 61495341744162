import { useState } from "react";
import { InteractiveButton } from "../../../components/atoms/InteractiveButton";
import { useInteractividad } from "../../../context/Interactividad";
import { SnackbarWithAlert } from "../../../components/molecules/SnackbarWithAlert";

export function ControlMenuSection({ onRefresh}) {
  const { 
    mode, 
    handleMode,
    sendEditedPlan,
  } = useInteractividad();

  const [post, setPost] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handlePost = async () => {
    const result = await sendEditedPlan();
    setPost(result);
    setSnackbarOpen(true);

    if (result?.status === "success") {
      onRefresh();
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  }

  return (
    <>
      <SnackbarWithAlert 
        severity={post?.status}
        message={post?.message}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 0"
        }}>
        <div 
          style={{
            display: "flex",
            columnGap: "1rem",
            padding: "0.8rem",
          }}>
          <InteractiveButton
            disabled={mode === "progress"}
            style={{
              pointerEvents: mode === "progress" ? "none" : "auto",
              opacity: mode === "progress" ? 0.5 : 1
            }}
            onClick={() => handleMode("plan")}
            variant="plan">
            Editar Plan
          </InteractiveButton>
          <InteractiveButton
            disabled={mode === "plan"}
            style={{
              pointerEvents: mode === "plan" ? "none" : "auto",
              opacity: mode === "plan" ? 0.5 : 1
            }}
            onClick={() => handleMode("progress")}
            variant="progress">
            Editar Avance
          </InteractiveButton>
        </div>
        {mode !== "none" && (
          <div
            style={{
              display: "flex",
              columnGap: "1rem",
              padding: "0.8rem 1.5rem",
            }}>
            <InteractiveButton 
              onClick={handlePost}
              variant="save">
              Guardar Cambios
            </InteractiveButton>
            <InteractiveButton
              onClick={() => handleMode("none")}
              variant="cancel">
              Cancelar
            </InteractiveButton>
          </div>
        )}
      </section>
    </>
  )
}