import React from "react";

export const WrapperCellProgreso = ({ 
  children, 
  style,
  className, 
  ...props
}) => {
  return (
    <section
      className={className}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        minWidth: "65px",
        boxShadow: "0 0 3px 0 rgba(0,0,0,0.1)",
        marginInline: "10px 6px",
        height: "46px",
        ...style
      }}
      {...props}>
        {children}
      </section>
  )
}

WrapperCellProgreso.displayName = "WrapperCellProgreso";