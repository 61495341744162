import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import '../../translator';
import { useTranslation } from 'react-i18next';
import { GeneralMinaContext } from "../../context/GeneralMina";
import { useContext } from "react";

const useStyles = makeStyles({
  list: {
    width: 220,
  },
  info: {
    "text-align": "center",
    "font-weight": "bold",
  },
  paper: {
    background: "#2f4554"
  }
});

const NavDrawerAdmin = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {onHandleGetEndpoint} = useContext(GeneralMinaContext);

  const [open, setOpen] = React.useState(false);
  const [expandMina, setExpandMina] = React.useState({
    id:3,
    name: "San Antonio",
    endPoint: "sanantonio",
    expand: false
  });

  const handleClickMinas = () => {
    setOpen(!open);
  };

  const handleClickExpandMina = (mina) => {
    if(mina.id===expandMina.id){
      setExpandMina({...expandMina, expand: !expandMina.expand});
    }
    else{
      setExpandMina({...mina, expand: true})
    }
  };
  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="left"
      open={props.drawerOpened}
      onClose={props.toggleDrawer(false)}
    >
      <div
        className={classes.list}
        /* onClick={props.toggleDrawer(false)} */
        onKeyDown={props.toggleDrawer(false)} 
        style={{height:"100%", color:"white"}}
      >
        <List>
          <ListItem className={classes.info}>
            <ListItemText primary="Menú" />
          </ListItem>
        </List>
        <Divider />

        <ListItemButton onClick={handleClickMinas}>
          <ListItemText primary="Minas" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
        {onHandleGetEndpoint().map((mina,i)=>{
            return(
              <div key={`${mina}-${i}`}>
                <ListItemButton onClick={()=>handleClickExpandMina(mina)}>
                  <ListItemText primary={mina.nombre_publico} />
                  {((expandMina.id===mina.id) && (expandMina.expand)) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={(expandMina.id===mina.id) && (expandMina.expand)} timeout="auto" unmountOnExit>            
                  {/* <List key={`ej${i}`} component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to={`/produccion/${mina.endpoint}`}>
                      <ListItemText primary={t("produccion")} />
                    </ListItemButton>
                  </List> */}
                  {
                    ![24].includes(mina.id_mina) && (
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to={`/control_frentes/${mina.endpoint}`}>
                          <ListItemText primary={t("control_frentes")} />
                        </ListItemButton>
                      </List>
                    )
                  }
                    {[24].includes(mina.id_mina) && (
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to={`/control_de_frentes/${mina.endpoint}`}>
                          <ListItemText primary={t("control_de_frentes")} />
                        </ListItemButton>
                      </List>
                    )}
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }} component={Link} to={`/administracion/${mina.endpoint}`}>
                        <ListItemText primary={t("administracion")} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }} component={Link} to={`/solicitudes/${mina.endpoint}`}>
                        <ListItemText primary={t("solicitudes")} />
                      </ListItemButton>
                    </List>
                  <List key={`justificacion${i}`} component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to={`/justificacion/macro/${mina.endpoint}`}>
                      <ListItemText primary={t("justificacion")} />
                    </ListItemButton>
                  </List>
                  {
                    [4,11,24].includes(mina.id_mina) && (
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to={`/control_diario/${mina.endpoint}`}>
                          <ListItemText primary={t("control_diario")} />
                        </ListItemButton>
                      </List>
                    )
                  }
                  {
                    ![4,11,24].includes(mina.id_mina) && (
                      <List key={`op${i}`} component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to={`/operaciones/${mina.endpoint}`}>
                          <ListItemText primary={t("operaciones")} />
                        </ListItemButton>
                      </List>
                    )
                  }
                  {
                    mina?.endpoint_externo.length > 0 && (
                        mina?.endpoint_externo?.map((rutaAux, index) =>(
                            <List key={"embedded"+index} component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to={`/${rutaAux.nombre}/${mina.endpoint}`}>
                              <ListItemText primary={t(rutaAux.nombre)} />
                            </ListItemButton>
                            </List>
                        ))
                    )
                  }
                  {
                    [1, 27].includes(mina.id_mina) && (
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }} component={Link} to={`/analytics/${mina.endpoint}`}>
                        <ListItemText primary={t("Analytics")} />
                      </ListItemButton>
                    </List>
                    )
                  }
                </Collapse>
              </div>
            )
          })}          
        </Collapse>

        <List>
          <ListItem button component={Link} to="/nivelcombustible">
            <ListItemText primary="Mine-Watch Fuel" />
          </ListItem>
        </List>

        <List>
          <ListItem button component={Link} to="/encuentros">
            <ListItemText primary="Encuentros" />
          </ListItem>
        </List>
        <List>
          <ListItem button component={Link} to="/registros">
            <ListItemText primary="Registros" />
          </ListItem>
        </List>
         <List>
          <ListItem button component={Link} to="/register">
            <ListItemText primary="Registrar Usuarios" />
          </ListItem>
        </List>
        <List>
          <ListItem button component={Link} to="/tablausuario">
            <ListItemText primary="Administrar Usuarios" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};
export default NavDrawerAdmin;