const backgroundColors = {
  plan: "#f89821",
  progress: "#06b7c4",
  save: "#4285f4",
  cancel: "#7e98ab",
}

export const InteractiveButton = ({ 
  children,
  disabled,
  style, 
  variant,
  onClick,
  ...props 
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        padding: "0.65rem 2rem",
        border: "none",
        borderRadius: "100vw",
        color: "#fff",
        fontWeight: "600",
        backgroundColor: backgroundColors[variant] || backgroundColors.plan,
        boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
        cursor: "pointer",
        transition: "backgrond-color 200ms ease", 
        userSelect: "none",
        ...style
      }}
      {...props}
    >
      {children}
    </button>
  )
}

InteractiveButton.displayName = "InteractiveButton";